const color = {
    black: '#2A2D39',
    white: '#FFFFFF',
    blueText: '#2E89E4',
    blue: '#66B8EF',
    green: '#8CD499',
    orange: '#F87A52',
}

export default color;
